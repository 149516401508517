import types from "rdx/modules/platform/types";
import createAction from "rdx/utils/createAction";

export default {
  getReminderNotifications: (payload) => createAction(types.GET_REMINDER_NOTIFICATIONS, payload),

  setReminderNotifications: (payload) => createAction(types.SET_REMINDER_NOTIFICATIONS, payload),

  getReminderNotificationsCount: (payload) => createAction(types.GET_REMINDER_NOTIFICATIONS_COUNT, payload),

  getInboxNotifications: (payload) => createAction(types.GET_INBOX_NOTIFICATIONS, payload),

  setInboxNotifications: (payload) => createAction(types.SET_INBOX_NOTIFICATIONS, payload),

  setReminderNotificationsCount: (payload) => createAction(types.SET_REMINDER_NOTIFICATIONS_COUNT, payload),

  toggleReminderNotificationViewedRequest: (payload) =>
    createAction(types.TOGGLE_REMINDER_NOTIFICATION_VIEWED_REQUEST, payload),

  toggleReminderNotificationViewed: (payload) => createAction(types.TOGGLE_REMINDER_NOTIFICATION_VIEWED, payload),

  setLeadToProposalDrawerVisible: (payload) => createAction(types.SET_LEAD_TO_PROPOSAL_DRAWER_VISIBLE, payload),

  setTeamAddLeadDrawerVisible: (payload) => createAction(types.SET_TEAM_LEAD_DRAWER_VISIBLE, payload),

  setProfileMentorshipTabVisible: (payload) => createAction(types.SET_PROFILE_MENTORSHIP_TAB_VISIBLE, payload),

  setLeadToProposalDrawerOpen: (payload) => createAction(types.SET_LEAD_TO_PROPOSAL_DRAWER_OPEN, payload),

  setLeadToProposalDrawerLeadId: (payload) => createAction(types.SET_LEAD_TO_PROPOSAL_DRAWER_LEAD_ID, payload),

  setLeadToProposalDrawerProjectId: (payload) => createAction(types.SET_LEAD_TO_PROPOSAL_DRAWER_PROJECT_ID, payload),

  setLeadToProposalDrawerProposalType: (payload) =>
    createAction(types.SET_LEAD_TO_PROPOSAL_DRAWER_PROPOSAL_TYPE, payload),

  clearLeadToProposalDrawerIds: (payload) => createAction(types.CLEAR_LEAD_TO_PROPOSAL_DRAWER_IDS, payload),

  setProfileOrganizationTabVisible: (payload) => createAction(types.SET_PROFILE_ORGANIZATION_TAB_VISIBLE, payload),

  getPlatformServices: (payload) => createAction(types.GET_PLATFORM_SERVICES, payload),

  setPlatformServices: (payload) => createAction(types.SET_PLATFORM_SERVICES, payload),

  setActivateAccountModalVisible: (payload) => createAction(types.SET_ACTIVATE_ACCOUNT_MODAL_VISIBLE, payload),

  setBackgroundLinesUrl: (payload) => createAction(types.SET_BACKGROUND_LINES_URL, payload),
  setcontainerOverFlow: (payload) => createAction(types.SET_OVERFLOW, payload),

  setInviteDrawerVisible: (payload) => createAction(types.SET_INVITE_DRAWER_VISIBLE, payload),

  setHomeownerNotesDrawerVisible: (payload) => createAction(types.SET_HOMEOWNER_NOTES_DRAWER_VISIBLE, payload),
  setHomeownerNotesLeadId: (payload) => createAction(types.SET_HOMEOWNER_NOTES_LEAD_ID, payload),
  clearHomeownerNotesLeadId: (payload) => createAction(types.CLEAR_HOMEOWNER_NOTES_LEAD_ID, payload),
  setBannersHeight: (payload) => createAction(types.SET_BANNERS_HEIGHT, payload),

  setIntegrityTrainingModalVisible: (payload) => createAction(types.SET_INTEGRITY_TRAINING_MODAL_VISIBLE, payload),

  setProposalRequestDrawerVisible: (payload) => createAction(types.SET_PROPOSAL_REQUEST_DRAWER_VISIBLE, payload),
  setProposalRequestDrawerId: (payload) => createAction(types.SET_PROPOSAL_REQUEST_DRAWER_ID, payload),
  clearProposalRequestDrawerId: () => createAction(types.CLEAR_PROPOSAL_REQUEST_DRAWER_ID),

  setAlertMessageVisible: (payload) => createAction(types.SET_ALERT_MESSAGE_VISIBLE, payload),
  clearAlertMessage: () => createAction(types.CLEAR_ALERT_MESSAGE),
  // INSERTION_PT (for script, do not remove)
};
