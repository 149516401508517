export default {
  GET_ENTERPRISE_PERSONNEL_LIST: "[enterprisePersonnel]: Get Enterprise Personnel List",

  SET_ENTERPRISE_PERSONNEL_LIST: "[enterprisePersonnel] SET Enterprise Personnel List",

  GET_LIST_OF_ASSIGNABLE_SERVICES: "[enterprisePersonnel] GET List Of Assignable Services",

  SET_LIST_OF_ASSIGNABLE_SERVICES: "[enterprisePersonnel] SET List Of Assignable Services",

  SET_ENTERPRISE_PERSONNEL_FILTERS: "[enterprisePersonnel] SET Enterprise Personnel Filters",

  SEND_ENTERPRISE_TEAM_MEMBER_INVITE: "[enterprisePersonnel] Send Enterprise Team Member Invite",

  GET_ENTERPRISE_USER_DETAILS: "[enterprisePersonnel] GET Enterprise User Details",

  SET_ENTERPRISE_USER_DETAILS: "[enterprisePersonnel] SET Enterprise User Details",

  GET_PENDING_SEAT: "[enterprisePersonnel] GET Pending Seat",

  SET_PENDING_SEAT: "[enterprisePersonnel] SET Pending Seat",

  RESET_ENTERPRISE_USER_DETAILS: "[enterprisePersonnel] Reset Enterprise User Details",

  UPDATE_ENTERPRISE_USER_SETTINGS: "[enterprisePersonnel] Update Enterprise User Settings",

  UPDATE_ENTERPRISE_USER_SERVICES: "[enterprisePersonnel] Update Enterprise User Services",

  SET_ENTERPRISE_SERVICES_SEAT_CHANGE_PREVIEW: "[enterprisePersonnel] SET Enterprise Services Seat Change Preview",

  GET_ENTERPRISE_SEAT_DETAILS: "[enterprisePersonnel] GET Enterprise Seat Details",

  SET_ENTERPRISE_SEAT_DETAILS: "[enterprisePersonnel] SET Enterprise Seat Details",

  CREATE_SEAT_FOR_EXISTING_LEVEL_ONE_USER: "[enterprisePersonnel] Create Seat For Existing Level One User",

  REJECT_SEAT_INVITE: "[enterprisePersonnel] Reject seat invite",

  ACCEPT_SEAT_INVITE: "[enterprisePersonnel] Accept seat invite",

  UPDATE_ENTERPRISE_USER_ROLES: "[enterprisePersonnel] Update Enterprise User Roles",
  // INSERTION_PT (for script, do not remove)
};
