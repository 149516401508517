import { put } from "redux-saga/effects";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* postNewAsset(action) {
  const { leadId, body, fileUrl: file_url } = action.payload;
  const { success, data, error } = yield* makeRequest.post(`/provider_leads/${leadId}/assets`, {
    body,
    file_url,
  });
  if (success && data) {
    yield put(actions.setAssets(data.body.assets.map(keysToCamelCase)));
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_POSTING_NEW_ASSET,
    });
  }
  return null;
}

export default postNewAsset;
