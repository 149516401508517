import React from "react";
import PropTypes from "prop-types";
import { Layout, Typography, Button } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { BorderedCloseIcon } from "@icons";
import styles from "@drawers/DrawerContainer/Drawer.module.less";
import RightArrow2 from "../../Icons/Arrows/rightArrow2";

const { Header } = Layout;
const { Title } = Typography;

const HandsomeHeader = (props) => {
  const { closeDrawer, title, customCloseIcon, subtitle, handleBackButton, detail, rightAction, rightText } = props;

  const handleBack = () => {
    handleBackButton();
  };

  return (
    <Header className={styles.handsomeHeader}>
      {closeDrawer && (
        <div onClick={closeDrawer} onKeyDown={closeDrawer} tabIndex={0} role="button" className={styles.close}>
          {customCloseIcon || <BorderedCloseIcon />}
        </div>
      )}
      {subtitle && (
        <Button className={styles.backButton} onClick={handleBack}>
          <RightArrow2 className={styles.arrow} fill="white" /> Back
        </Button>
      )}
      <div className={styles.title}>{title}</div>
      {subtitle && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />

          <Title className={styles.subtitle}>{subtitle}</Title>
        </>
      )}
      {detail && (
        <>
          <RightOutlined className={styles.title} style={{ margin: "0 10px" }} />

          <Title className={styles.subtitle}>{detail}</Title>
        </>
      )}
      {rightAction && rightAction.visible && (
        <Button className={styles.rightAction} onClick={rightAction.action}>
          {rightAction.text}
          <RightArrow2 className={styles.arrow} fill="white" />
        </Button>
      )}
      {rightText && !rightAction.visible && (
        <>
          <span className={styles.rightTextNumber}>{rightText.number}</span>
          <span className={styles.rightTextContent}>{rightText.text}</span>
        </>
      )}
    </Header>
  );
};

HandsomeHeader.propTypes = {
  closeDrawer: PropTypes.func,
  rightAction: PropTypes.shape({
    action: PropTypes.func,
    text: PropTypes.string,
    visible: PropTypes.bool,
  }),
  rightText: PropTypes.shape({
    text: PropTypes.string,
    number: PropTypes.number,
  }),
  customCloseIcon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.string,
  handleBackButton: PropTypes.func,
  detail: PropTypes.string,
};

export default HandsomeHeader;
