import React, { useMemo } from "react";
import PropTypes from "prop-types";

import User from "models/User";
import Ticket from "models/Ticket/TicketModel";

import { Row, Avatar } from "antd";

import PersonPopover from "components/UserCards/PeopleList/PersonPopover";
import TeamPopover from "components/Tables/TicketsTable/TeamPopover";
import TeamAvatar from "components/UserCards/TeamInfo/teamAvatar";
import ConferenceBadgeAvatar from "components/Avatars/BadgeAvatar/ConferenceBadgeAvatar";

import styles from "./styles.module.less";

const UserDisplay = (props) => {
  const { ticket, user, team, relationToTicket, relationToProject, showPopover, preferAssigned, disabled } = props;

  if (!user && !team) {
    throw new Error("User display expected one of User or Team, but both are undefined");
  }

  const projectRelation = (id) => {
    if (!ticket || !id) return null;
    if (ticket.isSeller(id)) return "Seller";
    if (ticket.isMentor(id)) return "Tier 3 Sales Partner";
    if (ticket.isProjectManager(id)) return "Project Manager";
    if (user.teams?.length) {
      if (user.teams.includes(12)) {
        return "Sales Support";
      }
      if (user.teams.includes(14)) {
        return "Finance";
      }
      return "Powur Operations";
    }
    return "";
  };

  const ticketRelation = (id) => {
    if (!ticket) return null;
    if (ticket.isAuthor(id) && !preferAssigned) return "Sent by";
    if (ticket.isAssigned(id)) return "Assigned to";
    if (ticket.isAuthor(id)) return "Sent by";
    return "";
  };

  const withUserPopover = (content) => {
    if (user) {
      return (
        <PersonPopover
          title={ticketRelation(user.id)}
          person={user}
          projectId={ticket.props.project.id}
          projectRelation={projectRelation(user?.id)}
        >
          {content}
        </PersonPopover>
      );
    }
    if (team) {
      return (
        <TeamPopover title={ticketRelation(team.id)} team={team}>
          {content}
        </TeamPopover>
      );
    }
    return content;
  };

  const customerCareAssigned = ticket?.props?.assignedToCustomerCare && ticket.isAssigned(user.id);

  const displayAvatar = () => {
    if (customerCareAssigned) {
      return <Avatar size={32} icon={<>CC</>} />;
    }
    return user ? (
      <ConferenceBadgeAvatar iconSize={{ height: "18px", width: "18px" }} size={32} user={user} />
    ) : (
      <TeamAvatar color={team.color} size={32} shortName={team.shortName} className={team.teamBadge} />
    );
  };

  const displayName = () => {
    if (customerCareAssigned) return "Customer Care";
    return user ? user.fullName : team.label;
  };

  const nameClass = disabled ? styles.disabledName : styles.name;
  const body = useMemo(() => {
    return (
      <div className={styles.container}>
        {displayAvatar()}

        <div className={styles.infoRows}>
          {relationToTicket && <Row className={styles.relToTicket}>{ticketRelation(user?.id || team?.id)}</Row>}
          <Row className={nameClass}>{displayName()}</Row>
          {relationToProject && (
            <Row className={styles.relToProject}>
              {!!user && projectRelation(user.id)}
              {!user && !!team && "Operations Team"}
            </Row>
          )}
        </div>
      </div>
    );
  }, [ticket, team, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{showPopover ? withUserPopover(body) : body}</>;
};

UserDisplay.propTypes = {
  relationToTicket: PropTypes.bool,
  relationToProject: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    avatar: PropTypes.shape({
      preview: PropTypes.string,
      thumb: PropTypes.string,
      retina: PropTypes.string,
      large: PropTypes.string,
    }),
    email: PropTypes.string,
    firstName: PropTypes.string,
    fullName: PropTypes.string,
    lastName: PropTypes.string,
    leadUserId: PropTypes.number,
    locale: PropTypes.string,
    phone: PropTypes.string,
    teams: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  }),
  team: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shortName: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
    teamBadge: PropTypes.string,
    sourceUid: PropTypes.string,
    users: PropTypes.arrayOf(User.types()),
  }),
  ticket: PropTypes.instanceOf(Ticket),
  showPopover: PropTypes.bool,
  preferAssigned: PropTypes.bool,
  disabled: PropTypes.bool,
};

UserDisplay.defaultProps = {
  relationToTicket: false,
  relationToProject: false,
  showPopover: false,
  preferAssigned: false,
};

export default UserDisplay;
