export default {
  selectReminderNotifications: (state) => state.platform.reminderNotifications,

  selectLeadToProposalDrawerVisible: (state) => state.platform.sales.projects.leadToProposalDrawerVisible,

  getTeamAddLeadDrawerVisible: (state) => state.platform.sales.team.addLeadDrawerVisible,

  getProfileMentorshipTabVisible: (state) => state.platform.personal.profile.profileMentorshipTabVisible,

  getProfileOrganizationTabVisible: (state) => state.platform.personal.profile.profileOrganizationTabVisible,

  selectLeadToProposalDrawerLeadId: (state) => state.platform.sales.projects.leadToProposalDrawerLeadId,

  selectLeadToProposalDrawerProjectId: (state) => state.platform.sales.projects.leadToProposalDrawerProjectId,

  selectLeadToProposalDrawerProposalType: (state) => state.platform.sales.projects.leadToProposalDrawerProposalType,

  getOfferLeadConfirmationModalVisible: ({ enterpriseProjects: { offerLeadConfirmation } }) =>
    !!offerLeadConfirmation && !!offerLeadConfirmation.consultant && !!offerLeadConfirmation.terms,

  selectPlatformServices: (state) => state.platform.gettingStarted.services,

  selectActivateAccountModalVisible: (state) => state.platform.activateAccountModalVisible,

  selectInboxNotificationsCount: (state) => state.platform.inbox.inboxNotificationsCount,

  selectBackgroundLinesUrl: (state) => state.platform.backgroundLinesUrl,

  selectcontainerOverFlow: (state) => state.platform.containerOverFlow,

  getInviteDrawerVisible: (state) => state.platform.sales.team.inviteDrawerVisible,

  selectHomeownerNotesDrawerVisible: (state) => state.platform.sales.projects.homeownerNotesDrawerVisible,
  selectHomeownerNotesLeadId: (state) => state.platform.sales.projects.homeownerNotesLeadId,
  selectBannersHeight: (state) => state.platform.bannersHeight,

  selectIntegrityTrainingModalVisible: (state) => state.platform.integrityTrainingModalVisible,

  selectProposalRequestId: (state) => state.platform.sales.projects.proposalRequestId,
  selectProposalRequestProjectId: (state) => state.platform.sales.projects.proposalRequestProjectId,
  selectProposalRequestDrawerDisabled: (state) => state.platform.sales.projects.proposalRequestDrawerDisabled,
  selectProposalRequestType: (state) => state.platform.sales.projects.proposalRequestType,

  selectAlertMessage: (state) => state.platform.alertMessage,
};
