import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxActionMapper, getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { useLocation, useHistory } from "react-router-dom";

import { cloneDeep } from "lodash";

import { parse, stringify } from "query-string";

import ModalContainer from "components/Modals/ModalContainer";
import styles from "./SeatRequestModal.module.less";

const SeatRequestModal = (props) => {
  const { rejectSeatInvite, acceptSeatInvite, getPendingSeat, pendingSeat } = props;

  const history = useHistory();
  const { search, pathname } = useLocation();

  const parsedSearch = parse(search);
  const pendingSeatId =
    typeof parsedSearch.seat_offer_modal === "string" && !Number.isNaN(Number(parsedSearch.seat_offer_modal))
      ? Number(parsedSearch.seat_offer_modal)
      : null;

  const pendingSponsorId =
    typeof parsedSearch.sponsor_id === "string" && !Number.isNaN(Number(parsedSearch.sponsor_id))
      ? Number(parsedSearch.sponsor_id)
      : null;

  const visible = pendingSeatId !== null;

  const { orgName, sellerPercentage, roleDisplay } = pendingSeat;

  useEffect(() => {
    if (visible) getPendingSeat({ pendingSeatId });
  }, [pendingSeatId, visible, getPendingSeat]);

  const close = () => {
    const searchClone = cloneDeep(parsedSearch);
    delete searchClone.seat_offer_modal;
    delete searchClone.sponsor_id;

    history.push({
      pathname,
      search: stringify(searchClone),
    });
  };

  const handleDecline = () => {
    rejectSeatInvite({ pendingSeatId });
    close();
  };

  const handleSubmit = () => {
    acceptSeatInvite({ pendingSeatId, pendingSponsorId });
    close();
    history.push("/dashboard");
  };

  const beginsWithVowel = (string) => {
    if (!string) return false;

    return /[aeiouAEIOU]/.test(string[0]);
  };

  return (
    <ModalContainer
      visible={visible}
      title="Enterprise Seat Request"
      handleSubmit={handleSubmit}
      submitText="Accept"
      cancelText="Decline"
      handleCancel={handleDecline}
      centerButtons
      zIndex={1202}
      footerText="Would you like to accept this offer?"
      headerClassName={styles.seatRequestModalHeader}
      cancelButtonStyles={{ width: "156px" }}
      submitButtonStyles={{ width: "156px" }}
      onClose={close}
      xClosable
    >
      <div className={styles.seatRequestModal}>
        <div className={styles.header}>
          <span>
            <span>{orgName}</span> wants you on their team.
          </span>
        </div>
        <div className={styles.body}>
          <span>
            The Powur Enterprise organization, <span className={styles.bold}>{orgName}</span>, has requested that you
            become a{beginsWithVowel(roleDisplay) && "n"} {roleDisplay || "seated seller"} on their team.
          </span>
        </div>
        <div className={styles.body2}>
          <span>
            In accepting this request the organization would pay your monthly Powur Energy subscription fee and your
            default compensation plan would be <span>{sellerPercentage}%</span> of the net revenue on self generated
            leads.
          </span>
        </div>
      </div>
    </ModalContainer>
  );
};

SeatRequestModal.propTypes = {
  rejectSeatInvite: PropTypes.func,
  acceptSeatInvite: PropTypes.func,
  getPendingSeat: PropTypes.func,
  pendingSeat: PropTypes.shape({
    orgName: PropTypes.string,
    sellerPercentage: PropTypes.number,
    roleDisplay: PropTypes.string,
  }),
};

export default connect(
  getRdxSelectionMapper({ pendingSeat: "selectPendingSeat" }),
  getRdxActionMapper(["rejectSeatInvite", "acceptSeatInvite", "getPendingSeat"]),
)(SeatRequestModal);
