import { put, select } from "redux-saga/effects";
import rbacSelectors from "rdx/modules/rbac/selectors";
import selectors from "rdx/selectors";
import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToCamelCase } from "lib/helpers";
import { isEmpty, forOwn, assign } from "lodash";

function* markMessagesAsViewed(action) {
  const { success, data, error } = yield* makeRequest.post("/messages/viewed", {
    message_ids: action.payload.messageIds,
  });
  const newMyProjectsCount = keysToCamelCase(data.body);

  if (success && data) {
    yield put(actions.updateMyProjectsCount(newMyProjectsCount.myProjectsCount));
    const readLength = action.payload.messageIds?.length;

    // INBOX:
    if (action.payload.parentIds?.length > 0) {
      const inboxList = yield select(selectors.getInboxList);
      const newInboxList = inboxList.map((message) =>
        action.payload.parentIds.includes(message.id)
          ? {
              ...message,
              counters: {
                ...message.counters,
                viewedCount: message.counters?.childCount,
              },
            }
          : message,
      );
      yield put(actions.setInboxList({ list: newInboxList }));
    } else if (action.payload.messageIds?.length > 0) {
      const inboxList = yield select(selectors.getInboxList);
      const newInboxList = inboxList.map((message) =>
        action.payload.messageIds.includes(message.id)
          ? {
              ...message,
              counters: {
                ...message.counters,
                viewedCount: message.counters?.childCount,
              },
            }
          : message,
      );
      yield put(actions.setInboxList({ list: newInboxList }));
    } else if (action.payload.isArchived === true) {
      const currentInboxUnviewedArchivedCount = yield select(rbacSelectors.getInboxUnviewedArchivedCount);
      const newInboxUnviewedArchivedCount = Math.max(0, currentInboxUnviewedArchivedCount - readLength);
      yield put(actions.updateInboxUnviewedArchivedCount(newInboxUnviewedArchivedCount));
    } else if (action.payload.isArchived === false) {
      const currentInboxUnviewedMessagesCount = yield select(rbacSelectors.getInboxUnviewedMessagesCount);
      const newInboxUnviewedMessagesCount = Math.max(0, currentInboxUnviewedMessagesCount - readLength);
      yield put(actions.updateInboxUnviewedMessagesCount(newInboxUnviewedMessagesCount));
    }

    // REPLIES:
    const projectMessageReplies = yield select(selectors.getProjectMessageReplies);
    const replies = {};
    forOwn(projectMessageReplies, (value, key) => {
      assign(replies, {
        [key]: value.map((x) => {
          return { ...x, viewed: true };
        }),
      });
    });
    if (!isEmpty(replies)) {
      yield put(actions.setProjectMessageReplies({ replies }));
    }

    // PROJECTS:
    const providerLeadsList = yield select(selectors.getProviderLeadsList);
    if (action.payload.projectId && !isEmpty(providerLeadsList?.providerLeads)) {
      const newList = providerLeadsList.providerLeads.map((proj) => {
        return proj.id === action.payload.projectId
          ? {
              providerLead: {
                ...proj,
                messageCounters: {
                  ...proj.messageCounters,
                  viewedCount: proj.messageCounters?.childCount,
                },
              },
            }
          : proj;
      });

      // Temp remove. Occasionally causing error. Workflow to recreate (I think): respond to unread message in Tickets, follow link to project detail, go to tickets tab, respond to message there.

      // const updatedLead = newList.filter(
      //   (proj) => proj.providerLead.id === action.payload.projectId,
      // );

      // const { childCount, viewedCount } =
      //   updatedLead[0].providerLead.messageCounters;
      yield put(
        actions.setProviderLeadsList({
          ...providerLeadsList,
          providerLeads: newList,
        }),
      );
      // yield put(
      //   actions.updateProjectBadgeCount({
      //     childCount,
      //     viewedCount,
      //   }),
      // );
    }
  } else if (error) {
    // ditto.
    return getErrorActions({
      error,
      // message: "",
      // payload: {},
    });
  }

  return null;
}

export default markMessagesAsViewed;
