import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import { keysToSnakeCase, camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* updateProviderLead(action) {
  const {
    providerLeadId,
    providerLead,
    utilityBillUrl,
    notes,
    enterprise,
    vssRoofRequest,
    contractOut,
    mentor_id,
    agent_id,
    siteInfo,
    existingSystem,
  } = action.payload;

  const params = {
    provider_lead: providerLead && keysToSnakeCase(providerLead),
    existing_system: existingSystem && keysToSnakeCase(existingSystem),
    utility_bill_url: utilityBillUrl,
    notes,
    enterprise,
    contractOut,
    site_info: siteInfo && keysToSnakeCase(siteInfo),
    mentor_id,
    agent_id,
    ...(vssRoofRequest && { vss_roof_request: keysToSnakeCase(vssRoofRequest) }),
  };
  const { success, data, error } = yield* makeRequest.patch(`/provider_leads/${providerLeadId}`, params);
  if (success && data) {
    yield all([
      put(actions.setProjectDetails({ providerLead: camelizeKeys(data.body) })),
      put(
        actions.newSuccessEvent({
          message: messages.UPDATE_PROVIDER_LEAD_SUCCESS,
        }),
      ),
    ]);
  } else {
    return yield put(actions.setFormErrors({ error }));
  }
  return null;
}

export default updateProviderLead;
