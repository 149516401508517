import { UpdatedCrmResponseBodyT } from "types/Project/crmActivity";
import { ProjectActivityCrmDataT } from "types/Project";

const formatCrmActivityForLog = (updatedActivity: UpdatedCrmResponseBodyT): ProjectActivityCrmDataT => {
  return {
    id: updatedActivity.id,
    leadId: updatedActivity.leadInfo.id,
    activityDate: updatedActivity.createdAt,
    canDelete: updatedActivity.canDelete,
    completedAt: updatedActivity.completedAt,
    completeType: updatedActivity.completeType,
    dueDate: updatedActivity.due,
    name: updatedActivity.kind,
    notes: updatedActivity.notes,
    title: updatedActivity.title,
    type: "activity",
  };
};

export default formatCrmActivityForLog;
