import React, { useState, useEffect } from "react";
import SettingsClient from "util/SettingsClient";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Drawer, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useParams, useLocation, Link } from "react-router-dom";
import { getRdxActionMapper } from "rdx/utils/propsMapping";
import { openLinkInNewTab } from "lib/props";
import useWindowResize from "hooks/useWindowResize";
import styles from "./MobileMenu.module.less";

const MobileMenu = (props) => {
  const { visible, toggle, zIndex, getZipLookupAddress } = props;
  const { t, i18n } = useTranslation(["footer", "translation", "form_labels"]);
  const { width } = useWindowResize();
  const [zip, setZip] = useState("");
  const [currentConsultantAgreementPath, setCurrentConsultantAgreementPath] = useState(
    "https://powur-public.s3.us-west-1.amazonaws.com/purchase_terms_and_conditions/Independent-Powur-Seller-Application-v1.0.pdf",
  );
  const locale = i18n.language;
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const settings = new SettingsClient();

  useEffect(() => {
    if (settings?.data?.current_consultant_agreement_path) {
      setCurrentConsultantAgreementPath(settings?.data?.current_consultant_agreement_path);
    }
  }, [settings.data]);

  const disclosureLinks = {
    en: "https://powur-public.s3.us-west-1.amazonaws.com/assets/incomeDisclosureOct2022.pdf",
    es: "https://powur-public.s3.us-west-1.amazonaws.com/assets/Income+Disclosure+Statement+2020_Spanish.pdf",
  };

  const closeThenNav = (linkTarget, hash) => {
    const { user } = params;
    const userTag = user ? `/${user}` : "";
    const nextPath = `${userTag}/${linkTarget}`;
    const nextLocation = { pathname: nextPath, search: location.search };
    if (hash) nextLocation.hash = hash;

    toggle();
    setTimeout(() => {
      history.push(nextLocation);
    }, 300);
  };

  const handleZipSubmit = () => {
    if (zip && zip.length === 5) {
      toggle();
      setTimeout(() => {
        getZipLookupAddress({ zip });
      }, 300);
    }
  };

  return (
    <Drawer
      className={styles.drawer}
      open={visible}
      placement="right"
      destroyOnClose={false}
      mask={false}
      width={width}
      zIndex={zIndex}
      closable={false}
      header={null}
      footer={null}
    >
      <div className={styles.menuContainer}>
        <ul className={styles.bigLinksList}>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("solar")}>
              {t("translation:why_solar")}
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("faq")}>
              FAQ
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("ambassador")}>
              {t("translation:ambassador")}
            </Button>
          </li>
          <li>
            <a className={styles.phoneLink} href="tel:1-866-GO-POWUR">
              1-866-GO-POWUR
            </a>
          </li>
        </ul>
        <div className={styles.zipContainer}>
          <Input
            className={styles.zipInput}
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            placeholder={t("form_labels:enter_zip_code")}
          />
          <Button className={styles.zipButton} type="primary" htmlType="button" onClick={handleZipSubmit}>
            {t("form_labels:get_quote")}
          </Button>
        </div>
        <hr className={styles.divider} />
        <ul className={styles.littleLinksList}>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("about")}>
              {t("our_team")}
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("about", "#mission")}>
              {t("our_mission")}
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("licenses")}>
              {t("licenses")}
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("careers")}>
              {t("careers")}
            </Button>
          </li>
          <li>
            <a {...openLinkInNewTab(disclosureLinks[locale])}>{t("income_disclosure")}</a>
          </li>
          <li>
            <a href="/store">{t("store")}</a>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("enterprise")}>
              Enterprise
            </Button>
          </li>
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("join")}>
              {t("translation:join_powur")}
            </Button>
          </li>
          <li>
            <Link to={{ pathname: "https://powur.com/privacy-policy.html" }} target="_blank">
              {t("form_labels:privacy_policy")}
            </Link>
          </li>
          {currentConsultantAgreementPath && (
            <li>
              <Link
                to={{
                  pathname: currentConsultantAgreementPath,
                }}
                target="_blank"
              >
                {t("form_labels:terms_and_conditions")}
              </Link>
            </li>
          )}
          {/* <li>
            <Button
              type="primary"
              htmlType="button"
              onClick={() => closeThenNav(`${userTag}/ambassador`)}
            >
              {t("translation:ambassador")}
            </Button>
          </li> */}
          <li />
          <li>
            <a href="https://support.powur.com">{t("visit_our_support_center")}</a>
          </li>
          <li />
          <li>
            <Button type="primary" htmlType="button" onClick={() => closeThenNav("login")}>
              Powur {t("form_labels:login")}
            </Button>
          </li>
        </ul>
      </div>
    </Drawer>
  );
};

MobileMenu.propTypes = {
  visible: PropTypes.bool,
  toggle: PropTypes.func,
  zIndex: PropTypes.number,
  getZipLookupAddress: PropTypes.func,
};

MobileMenu.defaultProps = {
  visible: false,
  zIndex: 1000,
};

export default connect(undefined, getRdxActionMapper(["getZipLookupAddress"]))(MobileMenu);
