import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getRdxSelectionMapper } from "rdx/utils/propsMapping";
import { browserHistory } from "rdx/configureStore";
import { Notifier } from "@airbrake/browser";
import SettingsClient from "util/SettingsClient";
import SessionClient from "util/SessionClient";
import StorageClient from "util/StorageClient";

const ErrorContext = React.createContext({});

class AirbrakeErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: {} };
    this.settings = new SettingsClient();
    this.session = new SessionClient();
    this.storage = new StorageClient();
    this.history = browserHistory;
    this.creds = {
      projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECT_ID),
      projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
      environment: process.env.REACT_APP_ENV,
    };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, errorInfo: info });

    if (!this.active) {
      return;
    }

    new Notifier(this.creds).notify({
      error,
      params: { info },
      context: this.errorContext,
    });

    /* 
      if error is due to a missing js chunk file, want to hard reload user's browser to get the
      missing files instead of showing error message.
    */
    const chunkError = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkError.test(error.message) && !this.storage.chunkFailed) {
      /*
        this.storage.chunkFailed checks if there has been a Loading chunk failed error in
        this browser within the last ten seconds. if true, then we don't want to reload the
        page again.
      */
      this.storage.chunkErrorWithExpiry = true;
      window.location.reload();
    }
  }

  get active() {
    return this.creds.projectId && this.creds.projectKey && !this.dev;
  }

  get dev() {
    return process.env.NODE_ENV === "development";
  }

  get errorContext() {
    const context = {
      reduxState: this.props.rdxState,
      location: this.history.location,
      href: this.history.createHref(this.history.location),
      session: this.session,
    };
    return context;
  }

  render() {
    return (
      <ErrorContext.Provider value={{ ...this.state }}>
        {this.state.hasError ? this.props.fallbackComponent : this.props.children}
      </ErrorContext.Provider>
    );
  }
}

AirbrakeErrorBoundary.propTypes = {
  // current state of redux store
  rdxState: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  fallbackComponent: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

AirbrakeErrorBoundary.defaultProps = {
  fallbackComponent: <></>,
};

export default connect(getRdxSelectionMapper({ rdxState: "getCurrentState" }, undefined))(AirbrakeErrorBoundary);
export { ErrorContext };
