const tabs = [
  {
    key: "summary",
    label: "Summary",
  },
  {
    key: "site-info",
    label: "Site Info",
  },
  {
    key: "workflow",
    label: "Workflow",
  },
  {
    key: "proposals",
    label: "Proposals",
  },
  {
    key: "contracts",
    label: "Contracts",
    conditionalRenderSelector: "getContractTabVisible",
  },
  {
    key: "site-survey",
    label: "Site Survey",
  },
  {
    key: "events",
    label: "Events",
  },
  {
    key: "assets",
    label: "Assets",
  },
  {
    key: "milestones",
    label: "Milestones",
  },
  {
    key: "commission",
    label: "Commission",
  },
  {
    key: "tickets",
    label: "Tickets",
  },
  {
    key: "activity-log",
    label: "Activity Log",
    breadcrumbLabel: "Project Activities",
  },
];

export default tabs;
