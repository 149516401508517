import React from "react";
import { omit } from "lodash";
import userflow from "userflow.js";
import SessionClient from "util/SessionClient";
import { isUndefined } from "types/guards";
import { useMount } from "react-use";

type UserFlowT = {
  user_id: number;
  user_created_at: string;
  name: string;
  email: string;
  sponsor_id: number;
  state: string;
  roles: { [key: string]: string };
  enrollment_date: string;
  preview_account: boolean;
  number_of_leads: number;
  number_of_installs: number;
  number_of_contracts: number;
  number_of_recruits: number;
  userflow_signature: string;
};

type UserFlowData = Omit<UserFlowT, "roles" | "userflow_signature"> | undefined;

const session = new SessionClient();
const userflowToken = process.env.REACT_APP_USERFLOW_TOKEN || "";

const InjectUserFlow = () => {
  const userFlow: UserFlowT | undefined = session.data.user_flow;
  const userFlowData: UserFlowData = omit(userFlow, ["roles", "userflow_signature"]);

  useMount(() => {
    userflow.init(userflowToken);
  });

  const initUserFlowAndIdentify = () => {
    if (!userflowToken || !userFlowData || isUndefined(userFlowData) || isUndefined(userFlowData?.user_id)) {
      return;
    }

    userflow.identify(
      userFlowData.user_id.toString(),
      { ...userFlowData, ...userFlow?.roles },
      { signature: userFlow?.userflow_signature },
    );

    userflow.setUrlFilter((url) => {
      const parsed = new URL(url);
      parsed.search = "";
      return parsed.toString();
    });
  };

  React.useEffect(() => {
    if (userFlowData) {
      initUserFlowAndIdentify();
    }
  }, [session]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default InjectUserFlow;
