import { put, select } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import selectors from "rdx/selectors";
import { camelizeKeys } from "lib/helpers";
import messages from "../messages";

function* getProjectProposals(action) {
  const { id, enterprise } = action.payload;
  const params = {
    enterprise,
  };
  const { success, data, error } = yield* makeRequest.get(`/provider_leads/${id}/proposals`, params);

  if (success && data) {
    const proposals = camelizeKeys(data.body);
    const projectDetails = yield select(selectors.getProjectDetails);

    yield put(
      actions.setProjectProposals({
        goSoloProposals: proposals.soloProposals,
        lightreachProposals: proposals.lightreachProposals,
        visionProposals: proposals.visionProposals,
        visionProposal: proposals.visionProposal,
      }),
    );

    yield put(
      actions.setProjectDetails({
        ...projectDetails,
        providerLead: {
          ...projectDetails.providerLead,
          canRequestSunnovaProposal: proposals.canRequestSunnovaProposal,
          sunnovaNonRequestableReason: proposals.sunnovaNonRequestableReason,
          canRequestEverbrightProposal: proposals.canRequestEverbrightProposal,
          everbrightNonRequestableReason: proposals.everbrightNonRequestableReason,
        },
      }),
    );
  } else {
    return getErrorActions({
      error,
      message: messages.ERROR_GETTING_PROPOSALS,
    });
  }
  return null;
}

export default getProjectProposals;
