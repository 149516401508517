import { put, all } from "redux-saga/effects";
import _ from "lodash";
import makeRequest from "rdx/utils/makeRequest";
import actions from "rdx/actions";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* updateAdminProjectLead(action) {
  const { projectId, leadId, lead, address } = action.payload;

  const params = {
    lead_id: leadId,
    lead: keysToSnakeCase(lead),
    address: keysToSnakeCase(address),
  };

  const { success, data, error } = yield* makeRequest.patch(`/projects/${projectId}`, params);
  if (success && data) {
    const out = keysToCamelCase(_.clone(data.body));
    yield all([
      put(actions.setAdminProjectDetails(out)),
      put(actions.setAdminProjectsList({ project: out })),
      put(actions.newSuccessEvent({ message: messages.PROJECT_UPDATE_SUCCESS })),
    ]);
  } else {
    return yield put(actions.setFormErrors({ error }));
  }
  return null;
}

export default updateAdminProjectLead;
