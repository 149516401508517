export default {
  OPEN_MENTOR_DRAWER: "Open select mentor drawer.",
  CLOSE_MENTOR_DRAWER: "Close select mentor drawer.",
  MENTOR_SELECT_SUCCESS: "This project has been assigned a mentor.",
  UPDATE_PROVIDER_LEAD_SUCCESS: "Project information updated.",
  UPDATE_PROJECT_LEAD_INFORMATION_SUCCESS: "Lead information updated.",
  REQUEST_PROPOSAL_SUCCESS: "Proposal request submitted.",
  SELECT_MENTOR_SUCCESS: "Mentor assignment updated.",
  REMOVE_MENTOR_SUCCESS: "Mentor removed.",
  REMOVE_AMBASSADOR_SUCCESS: "Ambassador removed.",
  ADD_AMBASSADOR_SUCCESS: "Ambassador added.",
  OPEN_PROPOSAL_REVISION_DRAWER: "Open proposal revision drawer.",
  CLOSE_PROPOSAL_REVISION_DRAWER: "Close proposal revision drawer.",
  REVISION_REQUEST_SUBMIT_SUCCESS: "Proposal revision request submitted.",
  POST_NEW_NOTE_SUCCESS: "Notice posted.",
  DELETE_NOTE_SUCCESS: "Notice deleted.",
  EDIT_NOTE_SUCCESS: "Notice updated.",
  CLOSE_PROJECT_SUCCESS: "Project closed.",
  REOPEN_PROJECT_SUCCESS: "Project reopened.",
  UPLOAD_COORDINATOR_CSV_SUCCESS: "Coordinators uploaded to projects.",
  INVITE_TO_MY_POWUR_SUCCESS: "MyPowur invite link sent",
  UNLOCK_REQUEST_SUCCESS: "Proposal unlock requested.",
  UPDATE_SITE_SURVEY_SCHEDULE_SUCCESS: "Site Survey Schedule Updated.",
  UPDATE_PRE_SALES_MILESTONES_SUCCESS: "Pre Sales Milestones Updated.",
  INVITE_TO_POWUR_SITE_SUCCESS: "Powur Site link sent",
  SEND_SALES_DESIGN_CONFIRMATION_SUCCESS: "Sales Design Confirmation sent",
  POST_SELLER_CHANGE_ORDER_SUCCESS: "Initiated change order confirmed",
  CREATE_TPO_WORKSHEET_SUCCESS: "TPO Pricing Worksheet Created",

  // error messages
  ERROR_GETTING_PROVIDER_LEADS_LIST: "There was an error retrieving projects list",
  ERROR_UPDATING_PROVIDER_LEAD: "There was an error updating project information",
  ERROR_UPDATING_PROJECT_LEAD_INFORMATION: "There was an error updating project lead information",
  ERROR_GETTING_PROJECT_NOTES: "There was an error retrieving project notices",
  ERROR_POSTING_NEW_NOTE: "There was an error posting your notice",
  ERROR_DELETING_NOTE: "There was an error deleting this notice",
  ERROR_EDITING_NOTE: "There was an error updating this notice",
  ERROR_DELETING_ASSET: "There was an error deleting this asset",
  ERROR_GETTING_PROJECT_DETAILS: "There was an error retrieving project details",
  ERROR_REQUESTING_PROPOSAL: "There was an error sending proposal request",
  ERROR_GETTING_PROPOSALS: "There was an error retrieving project proposals",
  ERROR_GETTING_PROPOSAL_DETAILS: "There was an error retrieving proposal details",
  ERROR_GETTING_VISION_PROPOSAL_COUNT: "There was an error retrieving vision proposal count",
  ERROR_GETTING_MENTOR_LIST: "There was an error retrieving mentor list",
  ERROR_SELECTING_MENTOR: "There was an error selecting a mentor",
  ERROR_SELECTING_AMBASSADOR: "There was an error selecting an ambassador",

  ERROR_SUBMITTING_PROPOSAL_REVISION: "There was an error submitting proposal revision",
  ERROR_CLOSING_PROJECT: "There was an error closing this project",
  ERROR_REOPENING_PROJECT: "There was an error reopening this project",
  ERROR_GETTING_PROJECTS_AUTOCOMPLETE: "There was an error retrieving projects",
  INVITE_HOMEOWNER_ERROR: "There was an error inviting this homeowner",
  ERROR_UPDATING_SITE_SURVEY_SCHEDULE: "There was an error updating the site survey schedule for this project.",
  ERROR_GETTING_SITE_SURVEY_SCHEDULE: "There was an error retrieving the site survey schedule for this project.",
  ALL_FIELDS_REQUIRED: "Site Survey was not submitted due to missing fields.",
  ERROR_GETTING_PRPOSAL_SETTINGS: "There was an error retrieving the proposal settings for this project",
  ERROR_GETTING_PROJECT_PAY_TERMS: "There was an error retrieving project pay terms",
  ERROR_CREATING_ROOFING_PROPOSAL: "There was an error creating a roofing proposal",
  ERROR_GETTING_PROJECT_INTEREST_RATES: "There was an error retrieving project interest rates",
  INVITE_TO_POWUR_SITE_ERROR: "There was an error sending the Powur Site link",
  ERROR_GETTING_PROJECTS_CSV: "There was an error getting the csv",
  ERROR_GETTING_PROVIDER_LEADS_KANBAN: "There was an error retrieving projects data",
  ERROR_GETTING_CONTRACT_DETAILS: "There was an error retrieving contract details",
  ERROR_GETTING_KPI_DATA: "There was an error retrieving KPI data",
  ERROR_SENDING_SALES_DESIGN_CONFIRMATION: "There was an error sending the sales design confirmation",
  ERROR_POSTING_SELLER_CHANGE_ORDER: "There was an error initiating the change order",
  ERROR_CREATING_TPO_WORKSHEET: "There was an error creating the TPO Pricing Worksheet",
  ERROR_GETTING_TPO_WORKSHEETS: "There was an error getting this project's TPO Pricing Worksheets",
};
