export default {
  getProjectDetails: (state) => state.projects.projectDetails,
  getProviderLeadsList: (state) => state.projects.providerLeadsList,
  selectProviderLeadsList: (state) => state.projects.providerLeadsList,
  getProviderLeadsListFilters: (state) => state.projects.providerLeadsFilters,
  getProjectsListAutoComplete: (state) => state.projects.projectsListAutoComplete,
  getProjectLeadsAutoComplete: (state) => state.projects.projectLeadsAutoComplete,
  getMentorsList: (state) => state.projects.mentors,
  selectPowurPairList: (state) => state.projects.powurPairs,
  getNotes: (state) => state.projects.notes,
  getTaggableUsers: (state) => state.inbox.inboxTags.userTags,
  getAssets: (state) => state.projects.assets,
  getProjectProposals: (state) => state.projects.proposals,
  selectProjectVisionProposals: (state) => state.projects.proposals.visionProposals,
  selectTpoPricingWorksheets: (state) => state.projects.proposals.tpoPricingWorksheets,
  selectTpoPricingWorksheetDetails: (state) => state.projects.proposals.tpoPricingWorksheetDetails,
  selectIsTpoPricingWorksheetRequested: (state) => state.projects.projectDetails.isTpoPricingWorksheetRequested,
  selectIsVisionProposalCreated: (state) => state.projects.proposals.isCreateVisionReqSuccessful,
  getRoofingProposals: (state) => state.projects.roofingProposals,
  selectRoofingProposalDetails: (state) => state.projects.roofingProposalDetails,
  selectLatestRoofingProposal: (state) => state.projects.latestRoofingProposal,
  getLeadsEventCounts: (state) => state.projects.eventCounts,
  getSiteSurveySchedule: (state) => state.projects.projectDetails.siteSurveySchedule,
  getProposalSettings: (state) => state.projects.proposalSettings,
  selectProjectCommission: (state) => state.projects.projectDetails.projectCommission,
  selectInterestRatesDrawerOpen: (state) => state.projects.projectDetails.interestRatesDrawerOpen,
  selectInterestRates: (state) => state.projects.projectDetails.interestRates,
  selectProjectsCsv: (state) => state.projects.projectsCsv,
  selectProviderLeadsCsv: (state) => state.projects.providerLeadsCsv,
  setRequestModalVisible: (state) => state.projects.soloRequestModalVisible,
  getRequestModalVisible: (state) => state.projects.soloRequestModalVisible,

  setRoofingModalVisible: (state) => state.projects.roofingModalVisible,
  getRoofingModalVisible: (state) => state.projects.roofingModalVisible,
  setRoofingModalTitle: (state) => state.projects.roofingModalTitle,
  getRoofingModalTitle: (state) => state.projects.roofingModalTitle,
  getRoofingNonReqReason: (state) => state.projects.roofingNonReqReason,
  setRoofingNonReqReason: (state) => state.projects.roofingNonReqReason,
  getHoveredProject: (state) => state.projects.hoveredProject,
  setHoveredProject: (state) => state.projects.hoveredProject,
  selectProviderLeadsKanban: (state) => state.projects.providerLeadsKanban,
  getContractTabComponent: (state) => state.projects.contractTabComponent,
  getContractTabVisible: (state) => state.projects.contractTabVisible,
  getContractDetails: (state) => state.projects.contractDetails,
  getCompareContractId: (state) => state.projects.compareContractId,
  getVisionProposalsCount: (state) => state.projects.visionProposalCount,
  selectKpiData: (state) => state.projects.kpi,
  getContractAsSoldDetails: (state) => state.projects.contractAsSoldDetails,
  getChangeRequestVisible: (state) => state.projects.changeRequestVisible,
  getCommissionTabVisible: (state) => state.projects.commissionTabVisible,
  selectProjectLookupOptions: (state) => state.projects.projectLookupOptions,
  selectCrmActivityLogFromDetail: (state) => state.projects.projectDetails.providerLead.activityLog,
  selectProjectCrmActivities: (state) => state.projects.projectDetails.providerLead.crmActivities,
  selectFundingProposals: (state) => state.projects.fundingProposals,
  selectFundingProposalDetails: (state) => state.projects.fundingProposalDetails,
  selectLatestIndexRoute: (state) => state.projects.latestIndexRoute,
  getContractVersion: (state) => state.projects.contractVersion,
  getContractById: (id) => (state) => state.projects.contractDetails.contracts.find((c) => c.contractId === id),
};
