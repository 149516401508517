// import content from "containers/Enterprise/enterpriseProjects/EnterpriseProjectDetails";
import tabs from "containers/Enterprise/enterpriseProjects/EnterpriseProjectDetails/tabs";
import breadCrumbs from "components/PlatformLayout/components/PlatformPageHeader/Breadcrumbs/ProjectBreadCrumbs";
import { permissionTypes } from "hooks/usePermissions";
import l from "@loadable/component";

const content = l(() => import("containers/Enterprise/enterpriseProjects/EnterpriseProjectDetails"));

// const breadCrumbs = l(() =>
//   import(
//     "components/PlatformLayout/components/PlatformPageHeader/ProjectBreadCrumbs"
//   ),
// );

const { ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR } = permissionTypes;

export default {
  key: "org-project-details",
  title: breadCrumbs,
  path: "/org-projects/:leadId?/:tab?/:subDrawer?",
  // exact: true,
  basePath: "org-projects/:leadId",
  requiredParams: ["leadId", "tab"],
  optionalParams: ["subDrawer"],
  linkTarget: "/org-projects",
  linkTitle: null,
  linkCategory: null,
  permission: [ENTERPRISE_SUPPORT, ENTERPRISE_ADMIN, ENTERPRISE_LEAD_GENERATOR],
  icon: null,
  tabs,
  content,
  sidebar: null,
};
