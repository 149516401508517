import { put, all } from "redux-saga/effects";

import makeRequest from "rdx/utils/makeRequest";
import getErrorActions from "rdx/utils/getErrorActions";
import actions from "rdx/actions";
import { keysToSnakeCase, keysToCamelCase } from "lib/helpers";
import messages from "../messages";

function* saveAndSubmitLead(action) {
  const { leadId, lead, address, siteInfo, enterprise, existingSystem } = action.payload;
  const params = {
    lead: keysToSnakeCase(lead),
    address: keysToSnakeCase(address),
    site_info: siteInfo && keysToSnakeCase(siteInfo),
    existing_system: existingSystem && keysToSnakeCase(existingSystem),
    enterprise,
  };
  const { success, data, error } = yield* makeRequest.patch(`/leads/${leadId}`, params);
  if (success && data) {
    yield all([
      put(actions.setLeadDetails(keysToCamelCase(data.body))),
      put(actions.submitLeadToProvider({ leadId, enterprise })),
      put(actions.getLeads()),
    ]);
  } else {
    return getErrorActions({ error, message: messages.ERROR_UPDATING_LEAD });
  }
  return null;
}

export default saveAndSubmitLead;
