import z from "zod";
import ZodSchemaUtil from "util/schema-helpers/ZodSchemaUtil";

export const EquipmentTypeSchema = z.union([
  z.literal("AUTOTRANSFORMER"),
  z.literal("BATTERY_COMPONENTS"),
  z.literal("CABLES"),
  z.literal("COMBINER"),
  z.literal("ENERGY_BACKUP"),
  z.literal("EV_CHARGER"),
  z.literal("EV_CHARGER_COMPONENTS"),
  z.literal("INVERTER"),
  z.literal("INVERTER_COMPONENTS"),
  z.literal("MISCELLANEOUS_ELECTRICAL"),
  z.literal("MODEM"),
  z.literal("MODULE"),
  z.literal("OPTIMIZER"),
  z.literal("RACKING"),
  z.literal("SMART_PANEL"),
  z.literal("SMART_PANEL_COMPONENT"),
  z.literal("TERMINATOR_CAP"),
]);

export const EquipmentSchema = z.object({
  name: z.string(),
  type: z.union([EquipmentTypeSchema, z.string()]),
  qty: z.number(),
  typeId: z.number(),
  price: z.number(),
});

export const AdderSchema = z.object({
  name: z.string(),
  qty: z.number(),
  cost: z.number(),
  type: z.string(),
});

export const RebateSchema = z.object({
  name: z.string(),
  paymentDate: z.string().nullable(),
  amount: z.number(),
});

const ChangeOrderAllowedTypesSchema = z.discriminatedUnion("name", [
  z.object({
    id: z.literal(1),
    name: z.literal("Absorb"),
  }),
  z.object({
    id: z.literal(2),
    name: z.literal("Shade Waiver"),
  }),
  z.object({
    id: z.literal(3),
    name: z.literal("Addendum"),
  }),
  z.object({
    id: z.literal(4),
    name: z.literal("Change Order"),
  }),
  z.object({
    id: z.literal(5),
    name: z.literal("SOW Confirmation"),
  }),
  z.object({
    id: z.literal(6),
    name: z.literal("ICO Only"),
  }),
]);

const ChangeOrderDateSchema = z.object({
  dateType: z.string(),
  completeDate: z.nullable(z.string()),
  approvalType: z.string().nullish(),
});

export const ChangeOrderSchema = z.object({
  allowedTypes: z.array(ChangeOrderAllowedTypesSchema),
  reasons: z.array(z.string()),
  designNotes: z.string(),
  changeOrderDates: z.array(ChangeOrderDateSchema),
  changeOrderType: z.nullable(z.string()),
  changeOrderTypeId: z.nullable(z.number()),
  versionCreatedDate: z.string(),
  versionCreatedBy: z.string(),
  changeOrderWorkFlowTypeName: z.string(),
  changeOrderWorkFlowType: z.number(),
});

export const ContractSchema = z.object({
  contractId: z.number(),
  changeOrder: ChangeOrderSchema.nullish(),
  fundingType: z.string(),
  systemSize: z.number(),
  equipment: z.array(EquipmentSchema),
  adders: z.array(AdderSchema),
  finalizedDate: z.string().nullable(),
  dateSigned: z.string(),
  totalContractPrice: z.number(),
  cashPayment: z.number(),
  totalFinanced: z.number(),
  loanTerm: z.number(),
  loanApr: z.number(),
  dealerFeePercent: z.number(),
  dealerFee: z.number(),
  estimatedAnnualProduction: z.number(),
  promotionalPayment: z.number().nullable(),
  federalTaxCredit: z.number(),
  secondPaymentWithIncentives: z.number(),
  secondPaymentWithoutIncentives: z.number(),
  cosignerName: z.nullable(z.string()),
  cosignerEmail: z.nullable(z.string()),
  cosignerFirstName: z.nullable(z.string()),
  cosignerlastName: z.nullable(z.string()),
  fundingTypeExcludeFromHso: z.boolean(),
  salesTax: z.number(),
  isTpo: z.boolean().nullable(),
  contractCosts: z.number(),
  estimatedNetRevenue: z.number(),
  rebates: z.array(RebateSchema),
  additionalCosts: z.array(z.object({ cost: z.number(), name: z.string() })),
});

export const DraftContractSchema = ZodSchemaUtil.makePropertiesNullable<typeof ContractSchema>(ContractSchema, [
  "contractId",
  "totalContractPrice",
  "changeOrder",
]);

const CommissionSheetItemSchema = z.object({
  id: z.number().nullish(),
  cost: z.number(),
  name: z.string(),
  sort: z.number().nullish(),
  type: z.number().nullish(),
});

export const CommissionSheetSchema = z.object({
  id: z.string(),
  approvalDate: z.string().nullish(),
  historyOfRevenue: z
    .array(
      z.object({
        revenue: z.number(),
        createdDateTime: z.string(),
        availableCommission: z.number(),
        recalcTriggeredFrom: z.string().nullish(),
      }),
    )
    .nullish(),
  issues: z.array(z.string()).nullish(),
  lineItems: z.array(CommissionSheetItemSchema),
  type: z.string().nullish(),
  updatedAt: z.string().nullish(),
});

export const DataTotalItemSchema = z.object({ name: z.string(), total: z.number() });

const AddersDataSchema = z.object({
  firstFinalizedContractAdders: z.array(DataTotalItemSchema),
  latestFinalizedContractAdders: z.array(DataTotalItemSchema),
  changeOrderAdders: z.array(z.object({ name: z.string(), total: z.number() })).nullish(),
  contractsAdders: z.array(
    z.object({
      contractId: z.number(),
      addersData: z.array(DataTotalItemSchema),
    }),
  ),
});

const SystemCostsListSchema = ZodSchemaUtil.makeStringLiteralUnion([
  "MODULE",
  "INVERTER",
  "RACKING",
  "OPTIMIZER",
  "MODEM",
  "ENERGY_BACKUP",
  "BALANCE_OF_SYSTEMS",
]);

export const SystemCostItemSchema = z.object({
  type: SystemCostsListSchema,
  name: z.string().nullable(),
  total: z.number().nullable(),
  label: z.string(),
  qty: z.number().nullish(),
});

const SystemCostsDataSchema = z.object({
  firstFinalizedContractSystemCosts: z.array(SystemCostItemSchema),
  latestFinalizedContractSystemCosts: z.array(SystemCostItemSchema),
  systemCostsList: z.array(SystemCostsListSchema),
  contractsSystemCosts: z.array(
    z.object({
      contractId: z.number(),
      systemCosts: z.array(SystemCostItemSchema),
    }),
  ),
  changeOrderSystemCosts: z.array(SystemCostItemSchema).nullish(),
});

const CoreServicesDataSchema = z.object({
  firstFinalizedContractServices: z.array(DataTotalItemSchema),
  latestFinalizedContractServices: z.array(DataTotalItemSchema),
  changeOrderServices: z.array(DataTotalItemSchema).nullish(),
  contractsCoreServices: z.array(
    z.object({
      contractId: z.number(),
      addersData: z.array(DataTotalItemSchema),
    }),
  ),
  coreServicesList: z.array(z.string()),
});

const TotalItemSchema = z.object({
  adders: z.number(),
  systemCosts: z.number(),
});

const TotalDataSchema = z.object({
  firstFinalizedTotals: TotalItemSchema,
  latestFinalizedTotals: TotalItemSchema,
  changeOrderTotals: TotalItemSchema.nullish(),
  contractsSystemCosts: z.array(z.object({ contractId: z.number(), total: z.number() })),
  contractsAdders: z.array(z.object({ contractId: z.number(), total: z.number() })),
});

const WorkFlowSlug = ZodSchemaUtil.makeStringLiteralUnion([
  "change_order",
  "sow",
  "admin",
  "buildable_as_sold",
  "buildable_with_changes",
  "change_order_required",
  "undetermined",
]);

export const ContractsResponseBodySchema = z.object({
  contracts: z.array(ContractSchema),
  submissionList: z
    .array(
      z.object({
        name: z.string(),
        value: z.string(),
        createdDateTime: z.string().nullable(),
      }),
    )
    .nullish(),
  commissionSheets: z.array(CommissionSheetSchema),
  commissionSheetOriginal: CommissionSheetSchema,
  changeOrder: ChangeOrderSchema.nullable(),
  draftContract: DraftContractSchema.nullable(),
  designLayoutUrl: z.string().nullable(),
  firstFinalizedContract: ContractSchema,
  latestFinalizedContract: ContractSchema,
  pendingDesignChange: z.boolean(),
  pendingDesignChangeCreatedAt: z.string().nullable(),
  addersData: AddersDataSchema,
  coreServicesData: CoreServicesDataSchema,
  roofTypeAsDesigned: z.string().nullable(),
  roofTypeAsSold: z.string().nullable(),
  systemCostsData: SystemCostsDataSchema,
  contractUpdates: z.record(z.array(z.string())).nullable(),
  salesConsultantInformed: z.boolean(),
  salesConsultantInformedAt: z.string().nullable(),
  totals: TotalDataSchema,
  workFlowSlug: WorkFlowSlug.nullish(),
  changeTeamHuddleLink: z.string().nullable(),
  approvedOn: z.string().nullish(),
  approvedBy: z.string().nullish(),
});

export const ProjectContractsResponse = z
  .object({
    body: ContractsResponseBodySchema,
  })
  .nullable();
