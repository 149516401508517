import { takeEvery } from "redux-saga/effects";
import trackRequests from "rdx/utils/trackRequests";

import types from "rdx/modules/enterprisePersonnel/types";

import getEnterprisePersonnel from "./getEnterprisePersonnel";
import getListOfAssignableServices from "./getListOfAssignableServices";
import sendEnterpriseTeamMemberInvite from "./sendEnterpriseTeamMemberInvite";
import getEnterpriseUserDetails from "./getEnterpriseUserDetails";
import updateEnterpriseUserSettings from "./updateEnterpriseUserSettings";
import updateEnterpriseUserServices from "./updateEnterpriseUserServices";
import getEnterpriseSeatDetails from "./getEnterpriseSeatDetails";
import createSeatForExistingLevelOneUser from "./createSeatForExistingLevelOneUser";
import rejectSeatInvite from "./rejectSeatInvite";
import acceptSeatInvite from "./acceptSeatInvite";
import getPendingSeat from "./getPendingSeat";

import updateEnterpriseUserRoles from "./updateEnterpriseUserRoles";
// IMPORT_PT (for script, do not remove)

function* watchEnterprisePersonnelSagas() {
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_PERSONNEL_LIST, getEnterprisePersonnel);
  yield trackRequests(takeEvery, types.GET_LIST_OF_ASSIGNABLE_SERVICES, getListOfAssignableServices);
  yield trackRequests(takeEvery, types.SEND_ENTERPRISE_TEAM_MEMBER_INVITE, sendEnterpriseTeamMemberInvite);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_USER_DETAILS, getEnterpriseUserDetails);
  yield trackRequests(takeEvery, types.GET_PENDING_SEAT, getPendingSeat);
  yield trackRequests(takeEvery, types.UPDATE_ENTERPRISE_USER_SETTINGS, updateEnterpriseUserSettings);
  yield trackRequests(takeEvery, types.UPDATE_ENTERPRISE_USER_SERVICES, updateEnterpriseUserServices);
  yield trackRequests(takeEvery, types.GET_ENTERPRISE_SEAT_DETAILS, getEnterpriseSeatDetails);
  yield trackRequests(takeEvery, types.CREATE_SEAT_FOR_EXISTING_LEVEL_ONE_USER, createSeatForExistingLevelOneUser);
  yield trackRequests(takeEvery, types.REJECT_SEAT_INVITE, rejectSeatInvite);
  yield trackRequests(takeEvery, types.ACCEPT_SEAT_INVITE, acceptSeatInvite);
  yield trackRequests(takeEvery, types.UPDATE_ENTERPRISE_USER_ROLES, updateEnterpriseUserRoles);
  // INSERTION_PT (for script, do not remove)
}

export default watchEnterprisePersonnelSagas;
