import z from "zod";
import { AddressSchema, AvatarSchema } from "../../users/schemas/users";

export const HomeownerSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  createdAt: z.string(),
  address: AddressSchema.partial(),
});

export const MilestoneCommissionSchema = z
  .object({
    id: z.number().or(z.undefined()),
    amount: z.string().or(z.undefined()),
    paid_to: z.string().or(z.undefined()),
    status: z.string().or(z.undefined()),
  })
  .or(z.undefined())
  .nullable();

const ProjectUserSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phone: z.string(),
  avatar: AvatarSchema,
  role: z.string(),
});

const ActivitySchema = z.object({
  id: z.number(),
  leadId: z.number(),
  projectStageId: z.number(),
  kind: z.string(),
  meetingType: z.string().nullable(),
  reminderType: z.array(z.string()),
  title: z.string(),
  email: z.string(),
  location: z.string(),
  meetingInfo: z.string(),
  notes: z.string(),
  due: z.string(),
  reminder: z.string(),
  archivedAt: z.string().nullable(),
  completeType: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  completedAt: z.string().nullable(),
  canDelete: z.boolean(),
});

export const LeadGenPreSalesProjectSchema = z.object({
  id: z.string(),
  projectStageMilestoneName: z.string().nullable(),
  projectStageName: z.string().nullable(),
  projectStageOrder: z.number(),
  projectStageId: z.number().nullable(),
  projectStageKind: z.number().nullable(),
  tpUpdatedAt: z.string().nullable(),
  coalescedUpdatedAt: z.string(),
  leadQualificationPercent: z.number(),
  homeowner: HomeownerSchema,
  projectUsers: z.array(ProjectUserSchema),
  activities: z.array(ActivitySchema),
});

export const LeadGenPostSalesProjectSchema = z.object({
  id: z.number(),
  updatedAt: z.string(),
  homeowner: HomeownerSchema,
  latestMilestone: z
    .object({
      id: z.number(),
      eventAt: z.string(),
      stageName: z.string(),
      milestoneName: z.string(),
    })
    .optional(),
  projectUsers: z.array(ProjectUserSchema),
  projectStatusDisplay: z.string().nullable(),
  projectStatusReason: z.string().nullable(),
  stage: z.string(),
  pm: ProjectUserSchema.nullable(),
  activities: z.array(ActivitySchema),
  projectStatus: z.object({
    name: z.string().nullish(),
    display: z.string().nullish(),
    reason: z.string().nullish(),
  }),
  commissions: z.object({
    m1: MilestoneCommissionSchema,
    m2: MilestoneCommissionSchema,
  }),
});

const PagingSchema = z.object({
  itemCount: z.number(),
  pageNumber: z.number(),
  pageSize: z.number(),
});

export const LeadGenProjectsPreSalesResponse = z.object({
  body: z.object({
    paging: PagingSchema,
    projects: z.array(LeadGenPreSalesProjectSchema),
  }),
});

export const LeadGenProjectsPostSalesResponse = z.object({
  body: z.object({
    paging: PagingSchema,
    providerLeads: z.array(LeadGenPostSalesProjectSchema),
  }),
});
