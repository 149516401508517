import React from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";

import styles from "./Spinner.module.less";

// Parent must have "position: relative" for the loading spinner container to be positioned correctly.

const Spinner = (props) => {
  const { children, loading, size, noMask, bgWhite, ...rest } = props;

  return loading ? (
    <div
      className={[
        ...(noMask ? [styles.loadingSpinnerNoMask] : [styles.loadingSpinner]),
        ...(bgWhite ? [styles.bgWhite] : []),
      ].join(" ")}
    >
      <div className={styles.loadingContainer}>
        <Spin className={styles.spinner} size={size} {...rest}>
          {children}
        </Spin>
      </div>
    </div>
  ) : null;
};

Spinner.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  noMask: PropTypes.bool,
  size: PropTypes.oneOf(["small", "middle", "large"]),
  bgWhite: PropTypes.bool,
};

Spinner.defaultProps = {
  size: "large",
  loading: false,
  children: null,
  bgWhite: false,
};

export default Spinner;
