import React from "react";
import PropTypes from "prop-types";
import { Layout, Button } from "antd";
import styles from "../../styles.module.less";

const DrawerErrorFallback = (props) => {
  return (
    <Layout className={styles.drawerFallback}>
      <Layout.Content className={styles.body}>
        <div>
          <div className={styles.oops}>Oops!</div>
          <div className={styles.message}>
            <p>
              We&apos;re sorry, something unexpected happened causing the Powur Energy app to crash. An automated error
              report has been sent that will help us address this issue. Refresh the page to return to Powur Energy.
            </p>
            <p>
              If this issue persists or you would like to send us feedback directly, please send us an email:{" "}
              <a href="mailto:support@powur.com">support@powur.com</a>.
            </p>
            <Button className={styles.closeDrawer} type="primary" htmlType="button" onClick={props.closeDrawer}>
              Close drawer and return to Powur Energy
            </Button>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  );
};

DrawerErrorFallback.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
};

export default DrawerErrorFallback;
